import React, { useEffect } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router';
import { Global } from '@emotion/core';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { globalStyle } from './globalStyle';
import { history } from '../common/helpers/history';
import Login from '../login/Login';
import Profile from '../profile/Profile';
import PrivateRoute from '../common/routes/PrivateRoute';
import { customTheme } from './theme';
import { PrivateLayout } from '../common/components/layouts/PrivateLayout';
import routes from '../common/routes';
import { authActions } from '../redux/auth/actions';
import { toastActions } from '../redux/toast/actions';
import { Operators } from '../operators';
import { Recipients } from '../recipients';
import Toast from '../common/components/Toasts/Toast';
import { Companies } from '../companies';
import { ErgoOffices } from '../ergoOffices';
import { Folders } from '../folders';
import { PreFolders } from '../preFolders';
import { Tasks } from '../tasks';
import { Dashboard } from '../dashboard';
import { ErrorHandler } from '../errors/ErrorHandler';
import { PageError404, PageErrorNetwork } from '../errors/components';
import { errorsActions } from '../redux/errors/actions';
import { Reporting } from '../reporting';
import { Quotes } from '../quotes';
import { Home } from './Home';
import { ProjectPlace } from '../projectPlace';
import { ErgoResources } from '../ergoResources';

const App = ({ logout, toast, clearToasts, resetErrors, errors, getProfile, currentUser }) => {
  /* Reset errors on navigation */
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (errors.error) {
        resetErrors();
      }
    });

    return unlisten;
  }, [errors]);

  /* Clear toast after a timer */
  useEffect(() => {
    let timer;

    if (toast.message) {
      timer = setTimeout(() => {
        clearToasts();
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [toast]);

  /* Update profile on navigation */
  useEffect(() => {
    function updateProfile() {
      if (
        !history.location.pathname.match(
          /(^\/$|login|logout|network|profil|(.+[0-9]{1,4}\/.+\/[0-9]{1,2}))/,
        )
      )
        getProfile(currentUser.id);
    }

    updateProfile();

    const unlisten = history.listen(() => {
      updateProfile();
    });

    return unlisten;
  }, [currentUser]);

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Global styles={globalStyle} />
      {toast.message && <Toast {...toast} />}
      <Router history={history}>
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <Route
            exact
            path={routes.logout}
            component={() => {
              logout();
              return <Redirect to={{ pathname: routes.login }} />;
            }}
          />
          <Route path={routes.error.network} exact component={PageErrorNetwork} />
          <Switch>
            <PrivateLayout>
              <ErrorHandler>
                <Switch>
                  <PrivateRoute path={routes.home} exact component={Home} />
                  <PrivateRoute path={routes.dashboard} exact component={Dashboard} />
                  <PrivateRoute path={routes.reporting} component={Reporting} />
                  <PrivateRoute path={routes.recipients} component={Recipients} />
                  <PrivateRoute path={routes.folders.index} component={Folders} />
                  <PrivateRoute path={routes.quotes.index} component={Quotes} />
                  <PrivateRoute path={routes.projectPlace} component={ProjectPlace} />
                  <PrivateRoute path={routes.preFolders} component={PreFolders} />
                  <PrivateRoute path={routes.tasks.index} component={Tasks} />
                  <PrivateRoute path={routes.users} component={Operators} />
                  <PrivateRoute path={routes.companies} component={Companies} />
                  <PrivateRoute path={routes.ergoResources} component={ErgoResources} />
                  <PrivateRoute path={routes.ergoOffices} component={ErgoOffices} />
                  <PrivateRoute path={routes.profile} exact component={Profile} />
                  <PrivateRoute component={PageError404} />
                </Switch>
              </ErrorHandler>
            </PrivateLayout>
          </Switch>
          <Route component={PageError404} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ toast, errors, authentication: { user: currentUser } }) => ({
  toast,
  errors,
  currentUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: authActions.logout,
      clearToasts: toastActions.clear,
      resetErrors: errorsActions.resetErrors,
      getProfile: authActions.getProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
