const routes = {
  error: {
    404: '/404-error',
    500: '/500-error',
    network: '/network-error',
  },
  login: '/login',
  logout: '/logout',
  profile: '/profil',
  home: '/',
  dashboard: '/dashboard',
  reporting: '/reporting',
  recipients: '/beneficiaires',
  preFolders: '/pre-dossiers',
  quotes: {
    index: '/demandes-de-devis',
    types: ['/dossiers-actifs', '/en-instance', '/archive', '/tous'],
  },
  projectPlace: '/project-place',
  folders: {
    index: '/dossiers',
    bloc_0: {
      index: '/ouverture-de-dossier',
      tabs: {
        1: [
          'requestSource',
          'doesRecipientOwnTheHousing',
          'appointedAt',
          'contactedAt',
          'housingStreetAddress',
          'housingPostalCode',
          'housingCity',
          'isFundingsSearchUseful',
        ],
        2: ['amoAmount', 'completeFolderSentAt', 'folderValidatedAt'],
      },
    },
    bloc_fio: {
      index: '/diagnostic-socio-administratif',
      tabs: {
        1: [
          'q10',
          'q11',
          'q12',
          'q13',
          'q14',
          'q15',
          'q16',
          'q17',
          'q18',
          'q19',
          'q1',
          'q20',
          'q21',
          'q22',
          'q2',
          'q3',
          'q4',
          'q5',
          'q6',
          'q7',
          'q8',
          'q9',
        ],
        2: [],
      },
    },
    bloc_c: {
      index: '/verification-des-pièces',
      tabs: {
        1: ['launchDocumentsReceivedAt', 'requiredLaunchDocuments'],
        2: ['cnavDeclarationDate'],
        3: ['fundingDocumentsReceivedAt', 'requiredFundingDocuments'],
      },
    },
    bloc_a: {
      index: '/diagnostic-ergothérapique',
      tabs: {
        1: [
          'ergoUserId',
          'recipientContactedAt',
          'isRecipientContacted',
          'housingVisitedAt',
          'visitReminderSentAt',
        ],
        2: [
          'adaptableHousing',
          'affectedHabits',
          'affectedRooms',
          'hasLifestyleAdviceWithEquipmentIntegration',
          'hasLpprTechnicalRecommandation',
          'hasTechnicalSheet',
          'housingCategory',
          'housingType',
          'humanHelpOrService',
          'isSpecificVehicleArrangementsRequired',
          'isTechnicalAssistanceRequired',
          'isWheelchairOrOtherEquipmentsRequired',
          'works',
        ],
        3: ['primary_2dpeFinalizedAt', 'sent_2dpeAt', 'updated_2dpeAt', 'is_2dpeCompleted'],
      },
    },
    bloc_d: {
      index: '/preparation-de-la-demande-de-devis',
      tabs: {
        1: ['isRecipient_2dpeValidationReceived', 'recipient_2dpeValidationReceivedAt'],
        2: ['isBiddingCompetitive', 'blocDCompanies'],
      },
    },
    bloc_e: {
      index: '/demande-de-devis',
      tabs: {
        1: ['recipientNotifiedAt'],
      },
    },
    bloc_f: {
      index: '/analyse-de-devis',
      tabs: {
        2: [
          'analysisFinishedAt',
          'confirmationFromRecipientReceivedAt',
          'projectTotalPrice',
          'sentToRecipientAt',
          'worksTotalPrice',
        ],
      },
    },
    bloc_g: {
      index: '/validation-du-financement',
      tabs: {
        1: [
          'fundingPlanSentAt',
          'hasOwnerAuthorizedWorks',
          'ownerAuthorizationReceivedAt',
          'ownerAuthorizationRequestSentAt',
        ],
        2: ['eligibleFundersMonitoring'],
        3: ['financialInvestigationEndDate'],
      },
    },
    bloc_h: {
      index: '/mise-en-oeuvre-des-preconisations',
      tabs: {
        1: [
          'bfpTransmissionType',
          'isRecipientContacted',
          'paidInvoicesReceivedAt',
          'projectEndFeedbackRequestSentAt',
          'projectEndReceivedAt',
          'projectEndReviewDate',
          'projectEndReviewPlannedAt',
          'projectLaunchInfosReceivedAt',
        ],
      },
    },
    bloc_i: {
      index: '/synthese-et-cloture',
      tabs: {
        1: ['closureCause', 'closureDocumentsSentAt'],
      },
    },
  },
  tasks: {
    index: '/taches',
    types: ['/actions-sur-dossier', '/envois-de-documents', '/appels', '/aleas'],
  },
  users: '/operateurs',
  companies: '/entreprises',
  ergoOffices: '/cabinets',
  ergoResources: '/ressources_methodologiques',
};

export const getFolderPath = (blocId, folderId, step = 1, targetAttribute) => {
  const blocPath = routes.folders[blocId].index;
  let tabIndex;

  if (targetAttribute)
    [tabIndex] = Object.entries(routes.folders[blocId].tabs).find(([, attributes]) =>
      attributes.find(attribute => attribute === targetAttribute),
    ) || [1];
  else tabIndex = step;

  return `${routes.folders.index}/${folderId}${blocPath}/${tabIndex}${
    targetAttribute ? `#${targetAttribute}` : ''
  }`;
};

export const getTaskRedirection = task => {
  switch (task.taskUid) {
    case 'bloc_a_primary_2dpe_finalized_at':
      return getFolderPath(task.targetBloc, task.folderId, '', 'adaptableHousing');
    default:
      return getFolderPath(task.targetBloc, task.folderId, '', task.targetAttribute);
  }
};

export const getTasksPath = (tasksType = 1) => {
  const tasksTypePath = routes.tasks.types[tasksType];

  return `${routes.tasks.index}${tasksTypePath}`;
};

export const getQuotesPath = (quotesType = 1) => {
  const quotesTypePath = routes.quotes.types[quotesType];

  return `${routes.quotes.index}${quotesTypePath}`;
};

export default routes;
