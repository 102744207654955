import { isErgoIntern, isExtern } from './Is';

export const RBArules = {
  administrateur: {
    static: [
      'dashboard:visit',
      'delete',
      'pre-folder:edit',
      'folder:edit',
      'tasks:read',
      'beneficiaries:read',
      'folder:read',
      'report:read',
      'comments:create',
      'comments:read',
      'comments:read:sensitive',
      'comments:edit',
      'comments:delete',
      'comments:create:sensitive',
      'companies:read',
      'companies:edit',
      'companies:edit_qualifications',
      'quotes:read',
      'self:edit',
      'data:medical:read',
      'project-place-work:read',
      'project-place-ergo:read',
      'ergo-resources:read',
      'ergo-resources:edit',
    ],
  },
  'pilote externe': {
    static: [
      'pre-folder:edit',
      'folder:bloc:read',
      'folder:bloc_0:read',
      'folder:bloc_c:read',
      'folder:bloc_a:read',
      'folder:bloc_d:read',
      'folder:bloc_e:read',
      'folder:bloc_f:read',
      'folder:bloc_g:read',
      'folder:bloc_h:read',
      'folder:bloc_i:read',
      'comments:create',
      'comments:read',
      'comments:read:sensitive',
      'report:read',
    ],
  },
  'référent externe': {
    static: [
      'pre-folder:edit',
      'folder:bloc:read',
      'folder:bloc_0:read',
      'folder:bloc_c:read',
      'folder:bloc_a:read',
      'folder:bloc_d:read',
      'folder:bloc_e:read',
      'folder:bloc_f:read',
      'folder:bloc_g:read',
      'folder:bloc_h:read',
      'folder:bloc_i:read',
    ],
  },
  ergothérapeute: {
    static: [
      'project-place-ergo:read',
      'project-place-ergo:position',
      'folder:edit',
      'ergo-resources:read',
      'self:edit',
    ],
    dynamic: {
      'project-place-work:read': isErgoIntern,
      'data:medical:read': isErgoIntern,
      'quotes:read': isErgoIntern,
      'comments:create': isErgoIntern,
      'comments:read': isErgoIntern,
      'comments:read:sensitive': isErgoIntern,
      'comments:edit': isErgoIntern,
      'comments:delete': isErgoIntern,
      'comments:create:sensitive': isErgoIntern,
      'companies:read': isErgoIntern,
      'companies:edit': isErgoIntern,
      'folder:read': isErgoIntern,
      'tasks:read': isErgoIntern,
      'beneficiaries:read': isErgoIntern,
      'folder:bloc:read': isExtern,
      'folder:bloc_a:read': isExtern,
      'folder:bloc_f:read': isExtern,
      'folder:bloc_h:read': isExtern,
    },
    exclude: {
      'folder:bloc_a:tab_1:type_of_visit:read': isExtern,
      'folder:bloc_a:tab_1:company_name:read': isExtern,
      'folder:bloc_a:tab_1:operators:read': isExtern,
      'folder:bloc_a:tab_1:visit_preparation:visit_reminder_sent_at:read': isExtern,
      'folder:bloc_a:tab_3:sent_2dpe_at:read': isExtern,
      'folder:bloc_f:tab_summary:read': isExtern,
      'folder:bloc_h:tab_1:form_section_1:read': isExtern,
    },
  },
  'conseiller social': {
    static: [
      'folder:edit',
      'folder:read',
      'tasks:read',
      'beneficiaries:read',
      'comments:create',
      'comments:read',
      'comments:read:sensitive',
      'comments:edit',
      'comments:delete',
      'comments:create:sensitive',
      'companies:read',
      'companies:edit',
      'self:edit',
      'data:medical:read',
      'project-place-work:read',
      'project-place-ergo:read',
      'quotes:read',
    ],
  },
  'coordinateur entreprises': {
    static: [
      'folder:bloc:read',
      'folder:bloc_e:read',
      'folder:bloc_f:read',
      'folder:bloc_g:read',
      'folder:bloc_h:read',
      'folder:bloc_i:read',
      'companies:read',
      'quotes:read',
      'project-place-work:read',
    ],
  },
  'référent entreprise': {
    static: ['quotes:read', 'project-place:position', 'comments:read'],
    dynamic: {
      'project-place-work:read': data =>
        data?.user?.companies?.find(company => company.hasAccessToProjectPlace),
    },
    exclude: ['comments:create', 'comments:read:sensitive']
  },
};
