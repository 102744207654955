import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, IconButton, Spinner, Tooltip } from '@chakra-ui/core';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { differenceInDays } from 'date-fns';
import { BaseTabs, TabList, TabWithBadge, TabPanels } from '../common/components/Tabs';
import routes, { getQuotesPath } from '../common/routes';
import { history } from '../common/helpers/history';
import { TabPanel, QUOTES_TYPES, TypeTag, TBody } from './components';
import { quotesActions } from '../redux/quotes/actions';
import { is } from '../roleBasedAccess/Is';
import { usersAPI } from '../common/api/users';
import { foldersAPI } from '../common/api/folders';
import { encodeQueryArray, getDateFromString } from '../common/utils';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SelectInput, SelectInputMultiple } from '../common/components/Form/FormSelectInput';
import { PrimaryButton, SecondaryButton } from '../common/components/Buttons';
import { Table, TBodyRow, TD, TH, Thead, THeadRow } from '../common/components/Table';
import { Funders } from '../common/components/Funders/Funders';
import QuoteUpload from '../common/components/QuoteUpload/QuoteUpload';
import Can from '../roleBasedAccess/Can';
import Comments from '../common/components/Comments/Comments';
import { Pagination } from '../common/components/Pagination';
import { QUOTE_GROUP_TYPES } from '../redux/blocs/blocE/actions';

const QuotesTabs = ({
  match: {
    params: { quotesType },
  },
  quotes,
  pending,
  getQuotes,
  updateQuotesComments,
  meta,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState({
    keywords: '',
    delay: '',
    tags: [],
    indicators: [],
  });

  useEffect(() => {
    getQuotes(currentPage, {
      keywords: search.keywords,
      delay: search.delay,
      tags: search.tags,
      status: quotesType,
      indicators: search.indicators,
    });
  }, [getQuotes, quotesType, search, currentPage]);

  if (!quotes) return null;

  const tabIndex = routes.quotes.types.indexOf(`/${quotesType}`);

  function metaCountByType(type) {
    if (!meta) {
      return 'N/A';
    }

    switch (type) {
      case 'green':
        return meta.activeCount;
      case 'orange':
        return meta.pendingCount;
      case 'red':
        return meta.closedCount;
      default:
        return meta.totalCount;
    }
  }

  return (
    <Box w="100%" px={10} pt={5}>
      <BaseTabs
        height="calc(100% - 36px)"
        defaultIndex={tabIndex >= 0 ? tabIndex : 0}
        onChange={index => history.push(getQuotesPath(index))}
      >
        <SearchBar onSearch={setSearch} meta={meta} quotesType={quotesType} />

        <TabList>
          {Object.entries(QUOTES_TYPES).map(([type, label]) => (
            <TabWithBadge
              key={type}
              badgeBgColor={`tag.secondaryBg.${type}`}
              badgeContent={metaCountByType(type)}
            >
              {label}
            </TabWithBadge>
          ))}
        </TabList>

        <TabPanels pt={8} pb={2}>
          {Object.keys(QUOTES_TYPES).map(type => (
            <TabPanel key={type}>
              <QuotesTable
                quotes={quotes}
                pending={pending}
                getQuotes={getQuotes}
                updateQuotesComments={updateQuotesComments}
                meta={meta}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                search={{ ...search, status: quotesType }}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </BaseTabs>
    </Box>
  );
};

const mapStateToProps = ({ quotes: state }) => {
  return {
    pending: state.pending,
    quotes: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getQuotes: quotesActions.getQuotes,
      updateQuotesComments: quotesActions.updateQuotesComments,
    },
    dispatch,
  );

const SearchBar = ({ onSearch, quotesType }) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [currentIndicators, setCurrentIndicators] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const { user } = useSelector(state => state.authentication);

  const form = useRef();

  useEffect(() => {
    if (is(user, 'pilote externe') || is(user, 'référent externe'))
      usersAPI
        .getUser(-1)
        .then(u => {
          setAvailableTags(u.tags);
        })
        .catch(console.log);
    else
      foldersAPI
        .getTags({ search: '' })
        .then(tags => {
          setAvailableTags(tags);
        })
        .catch(console.log);
  }, [setAvailableTags, user]);

  const openQuotesReport = () => {
    const keywords = form.current.values.search || '';
    const delay = form.current.values.delay?.value || '';

    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/quote_request_summaries/export?by_keywords=${keywords}&by_delay=${delay}&by_status=${quotesType}${encodeQueryArray(
      'by_tags',
      currentTags,
    )}${encodeQueryArray('by_indicators', currentIndicators)}`;
    window.open(url);
  };

  return (
    <Flex isInline justifyContent="space-between" w="100%">
      <Formik
        innerRef={form}
        initialValues={{ search: '', delay: '' }}
        onSubmit={values =>
          onSearch({
            keywords: values.search,
            delay: values.delay?.value || values.delay,
            tags: currentTags,
            status: currentStatus,
            indicators: currentIndicators,
          })
        }
      >
        {formik => (
          <Box
            as="form"
            onSubmit={formik.handleSubmit}
            d="flex"
            alignItems="start"
            flexGrow="1"
            minW={0}
            mr={3}
          >
            <Box d="flex" flexDir="column" width="100%" flexGrow={1} minW={0}>
              <SearchInput name="search" />
              <Box d="flex" alignItems="start" flexWrap="wrap" mt={2}>
                <SelectInputMultiple
                  placeholder="Filtrer par TAGS"
                  styles={{ flexGrow: 1, maxW: '24%', mr: 3, textTransform: 'uppercase' }}
                  options={availableTags}
                  menuOptionGroupOptions={{
                    onChange: setCurrentTags,
                    value: currentTags,
                    clear: () => {
                      setCurrentTags([]);
                    },
                  }}
                  label="Tag"
                />
                <SelectInput
                  name="delay"
                  isClearable
                  placeholder="Délais"
                  styles={{
                    flexGrow: 1,
                    maxW: '24%',
                    mr: 3,
                  }}
                  css={{
                    '[class*=-indicatorContainer]:first-of-type': {
                      position: 'static',
                    },
                  }}
                  size="sm"
                  options={[
                    { label: '< 15j', value: '< 15' },
                    { label: '15j < x < 45j', value: '15 < x < 45' },
                    { label: '> 45j', value: '> 45' },
                  ]}
                />
                <SelectInputMultiple
                  styles={{
                    flexGrow: 1,
                    maxW: '24%',
                    mr: 3,
                  }}
                  options={[
                    'Diagnostic en attente de programmation',
                    'Diagnostic en attente de réalisation',
                    'Devis non reçu',
                    'Devis reçu en cours d’analyse',
                    'Demande de modifications',
                    'Devis conforme en attente de transmission au bénéficiaire',
                    'Devis conforme en attente de retour du bénéficiaire',
                    'Devis sans suite par le bénéficiaire',
                    'Devis choisi par le bénéficiaire - attente de dernières modifications',
                    'Devis choisi par le bénéficiaire - en attente de transmission aux financeurs',
                    'Devis choisi par le bénéficiaire',
                    'Devis transmis aux financeurs - instruction en cours',
                    'Notification de financeurs reçue',
                    'BFP réalisé - réserves émises',
                    'BFP réalisé - sans réserve',
                    'Projet réalisé - en attente de BFP',
                  ]}
                  menuOptionGroupOptions={{
                    onChange: setCurrentIndicators,
                    value: currentIndicators,
                    clear: () => {
                      setCurrentIndicators([]);
                    },
                  }}
                  label="Indicateurs"
                />
              </Box>
            </Box>
            <SecondaryButton type="submit" size="sm" ml={3}>
              Lancer la recherche
            </SecondaryButton>
          </Box>
        )}
      </Formik>
      <Box>
        <PrimaryButton type="button" size="sm" onClick={openQuotesReport}>
          Télécharger
        </PrimaryButton>
      </Box>
    </Flex>
  );
};

const QuotesTable = ({
  quotes,
  pending,
  getQuotes,
  updateQuotesComments,
  meta,
  currentPage,
  setCurrentPage,
  search,
}) => {
  const { user } = useSelector(state => state.authentication);

  return (
    <>
      <Table d="flex" flexDirection="column" px={8} h="94%">
        <Thead d="block" pos="sticky" zIndex="docked">
          <THeadRow
            d="grid"
            gridTemplateColumns="0.25fr 75px 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 75px 100px"
          >
            <TH size="sm">
              Nom de
              <br />
              l'entreprise
            </TH>
            <TH size="sm">
              N° de
              <br />
              dossier
            </TH>
            <TH size="sm">Bénéficiaire</TH>
            <TH size="sm">Ville</TH>
            <TH size="sm">Indicateur</TH>
            <TH size="sm">Statut</TH>
            <TH size="sm">Date d'affectation</TH>
            <TH size="sm">
              {user.role === 'coordinateur entreprises' ? 'Tag' : 'Type de dossier'}
            </TH>
            <TH size="sm">Détails</TH>
            <TH size="sm">Actions</TH>
          </THeadRow>
        </Thead>
        <TBody>
          {!quotes || quotes.length === 0 || pending ? (
            pending ? (
              <Box
                d="flex"
                color="semiDarkGray.600"
                mt={8}
                alignItems="center"
                justifyContent="center"
              >
                <Spinner label="Chargement..." />
              </Box>
            ) : (
              <Box
                d="flex"
                color="semiDarkGray.600"
                mt={8}
                alignItems="center"
                justifyContent="center"
              >
                Aucun résultat
              </Box>
            )
          ) : (
            quotes.map((quote, index) => {
              const {
                id,
                quoteGroupId,
                companyId,
                companyName,
                folderFormattedId,
                recipientFullName,
                recipientLastName,
                recipientFirstName,
                folderId,
                folderState,
                folderFormattedState,
                comments,
                recipientHousingCity,
                quoteRequestSentAt,
                status,
                lastQuoteUploaded,
                folderTag,
                indicator,
                typeOfVisit,
              } = quote;

              const nbOfDaysDelayQuoteRequestSentAt = quoteRequestSentAt
                ? differenceInDays(
                    new Date().setHours(0, 0, 0, 0),
                    getDateFromString(quoteRequestSentAt),
                  )
                : null;

              let variantColor = 'blue';
              let folderType = 'Devis après rapport ergo';

              if (
                nbOfDaysDelayQuoteRequestSentAt > 45 &&
                indicator?.match(
                  /(Devis non reçu|Devis reçu en cours d’analyse|Devis reçu en cours d’analyse\*|Devis conforme en attente de transmission au bénéficiaire|Demande de modifications)/,
                )
              ) {
                variantColor = 'red';
              }

              if (
                nbOfDaysDelayQuoteRequestSentAt > 15 &&
                nbOfDaysDelayQuoteRequestSentAt <= 45 &&
                indicator?.match(
                  /(Devis non reçu|Devis reçu en cours d’analyse|Devis reçu en cours d’analyse\*|Devis conforme en attente de transmission au bénéficiaire|Demande de modifications)/,
                )
              ) {
                variantColor = 'orange';
              }

              if (typeOfVisit === 'joint') {
                folderType = 'VAD conjoint';
              }
              if (typeOfVisit === 'existing_quote') {
                folderType = 'Intervention sur devis';
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <TBodyRow
                  key={index}
                  hasScaleOnHover={false}
                  enableHover={false}
                  d="grid"
                  gridTemplateColumns="0.25fr 75px 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 75px 100px"
                >
                  <TD hasPadding size="sm">
                    {companyName}
                  </TD>
                  <TD hasPadding size="sm">
                    {folderFormattedId}
                  </TD>
                  <TD hasPadding size="sm">
                    {recipientFullName}
                  </TD>
                  <TD hasPadding size="sm">
                    {recipientHousingCity}
                  </TD>
                  <TD hasPadding size="sm">
                    {indicator}
                  </TD>
                  <TD hasPadding size="sm">
                    {folderState === 'Actif' ? status : folderFormattedState}
                  </TD>
                  <TD hasPadding size="sm">
                    {nbOfDaysDelayQuoteRequestSentAt === null ||
                    nbOfDaysDelayQuoteRequestSentAt < 0 ? (
                      'En attente'
                    ) : (
                      <TypeTag variantColor={variantColor} whiteSpace="nowrap">
                        {quoteRequestSentAt} (+{nbOfDaysDelayQuoteRequestSentAt}j)
                      </TypeTag>
                    )}
                  </TD>
                  <TD
                    hasPadding
                    size="sm"
                    textTransform={user.role === 'coordinateur entreprises' ? 'uppercase' : null}
                  >
                    {user.role === 'coordinateur entreprises' ? folderTag : folderType}
                  </TD>

                  <TD hasPadding size="sm" textAlign="center">
                    <Funders folderId={quote.folderId} />
                  </TD>
                  <TD textAlign="center" whiteSpace="nowrap" hasPadding size="sm">
                    <QuoteUpload
                      folder={{ formattedId: folderFormattedId, recipientFullName }}
                      quote={quote}
                      quoteRequest={{ id, quoteGroupId, companyId, indicator }}
                      successCallback={() => getQuotes(currentPage, search)}
                    />

                    <Tooltip
                      bg="semiDarkGray.900"
                      label="Voir le devis en ligne"
                      placement="top"
                      zIndex={1000}
                      aria-label="Voir le devis en ligne"
                    >
                      <IconButton
                        as="a"
                        icon="view"
                        variant="ghost"
                        variantColor={null}
                        isDisabled={!lastQuoteUploaded?.downloadLink}
                        href={lastQuoteUploaded?.downloadLink}
                        target="_blank"
                        color="coral.900"
                        height={8}
                        minW={8}
                        rounded="full"
                        aria-label="Voir le dernier devis"
                        _hover={{
                          '&:not([aria-disabled])': {
                            color: 'white',
                            backgroundColor: 'coral.900',
                          },
                        }}
                        _focus={{ shadow: 'none' }}
                      />
                    </Tooltip>

                    <Can
                      perform="comments:read"
                      yes={() => (
                        <Comments
                          folder={{
                            formattedId: folderFormattedId,
                            id: folderId,
                            state: folderState,
                            recipientFirstName,
                            recipientLastName,
                          }}
                          comments={comments}
                          updateFallback={updatedFolder => updateQuotesComments(id, updatedFolder)}
                        />
                      )}
                    />
                  </TD>
                </TBodyRow>
              );
            })
          )}
        </TBody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesTabs);
